<template>
  <v-container
    id="wizard"
    tag="section"
    fluid
    class="light-blue lighten-5 px-1 ca-term"
  >
    <otp-verification
      path="src/assets/images/termlife-icon.png"
      filename="termlife-icon.png"
    />
    <v-dialog
      v-model="errorMessageDialog"
      max-width="700"
      persistent
    >
      <v-card min-height="33vh">
        <v-card-title class="justify-center font-weight-bold text-h5">
          {{ $t('common.apiErrors.title') }}
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>{{ errorMessage }}</v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="clearErrorMessage"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-overlay v-if="loading && !isCalenderClicked">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
      <v-row v-if="applicationStep>=7">
        <v-col
          class="d-flex justify-center"
          style="color:rgb(0 224 255);text-align: center;
          font-size: 1.2rem;
          padding: 1.5rem;"
        >
          {{ $t('common.finalLoadingText') }}
        </v-col>
      </v-row>
    </v-overlay>
    <div v-if="isJourneyLoaded">
      <div v-if="!userpresent">
        <login-signup
          :parent="this"
          :assessment-data="combinedData.step2"
        />
      </div>
      <div v-if="userpresent">
        <v-dialog
          v-model="afterHoursDialog"
          data-testid="afterHoursDialog"
          persistent
          max-width="400"
        >
          <v-card>
            <v-card-title class="primary--text font-weight-bold">
              {{ $t('caTerm.afterHours.title') }}
            </v-card-title>
            <v-card-text>
              <p class="font-weight-bold text-center">
                {{ $t('caTerm.afterHours.time') }}
              </p>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="font-weight-bold mr-0"
                @click="afterHoursDialog = false"
              >
                {{ $t('common.buttons.continue') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="applicationNotFoundDialog"
          data-testid="applicationNotFoundDialog"
          persistent
          max-width="400"
        >
          <v-card>
            <v-card-title class="primary--text font-weight-bold">
              Oops!
            </v-card-title>
            <v-card-text>
              <p class="font-weight-bold text-center">
                No application found
              </p>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="font-weight-bold mr-0"
                @click="()=>{
                  applicationNotFoundDialog = false
                  $router.push('/userpolicies');
                }"
              >
                {{ $t('common.buttons.continue') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isQcOrOn"
          data-testid="isQcOrOn"
          persistent
          max-width="400"
        >
          <v-card>
            <v-card-text>
              <p class="font-weight-bold text-center">
                {{ $t('isQcorOnMessage') }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="font-weight-bold mr-0"
                @click="isQcOrOn = false"
              >
                {{ $t('close') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-------------------------------------------STEPPER--------------------------------------->
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="11"
            class="px-0 max-width-lg"
          >
            <v-card
              style="border-radius: 20px;"
              elevation="0"
              class="px-0"
            >
              <v-stepper
                v-if="!isPolicyPuchased"
                v-model="applicationStep"
                elevation="0"
              >
                <step-header
                  :parent="currentScope"
                  :combined-data="combinedData"
                />
                <v-stepper-items>
                  <!----------------------- STEP 1: ELIGIBILITY/INITIAL INFO------------------------------------>
                  <pre-application
                    :parent="currentScope"
                    @step-ref="updateCombinedData('step1', $event)"
                  />
                  <!----------------------- STEP 2: POLICY OPTIONS------------------------------------>
                  <assessment
                    :parent="currentScope"
                    @step-ref="updateCombinedData('step2', $event)"
                  />
                  <!----------------------- STEP 3: Lifestyle and medical section------------------------------------>
                  <questions
                    :parent="currentScope"
                    @weight-set="handleWeightSet"
                    @step-ref="updateCombinedData('step3', $event)"
                  />
                  <!-----------------------------STEP 4: Personal Statement------------------------------->
                  <terms
                    :parent="currentScope"
                    @step-ref="updateCombinedData('step4', $event)"
                  />
                  <!-----------------------------STEP 5: Benefciary Page------------------------------->
                  <beneficiaries
                    :parent="currentScope"
                    @step-ref="updateCombinedData('step5', $event)"
                  />
                  <!-----------------------------STEP 6: Policy Plan & Confirm Identity------------------------------->
                  <offer-summary
                    :parent="currentScope"
                    @step-ref="updateCombinedData('step6', $event)"
                  />
                  <!-------------------------------------------STEP 7: Payment----------------------------------------------->
                  <payment :parent="currentScope" />
                </v-stepper-items>
              </v-stepper>
              <!-------------------------------------------Policy----------------------------------------------->
              <ca-term-thanks
                v-if="isPolicyPuchased"
                :parent="currentScope"
              />
            </v-card>
          </v-col>
        </v-row>
        <div class="mb-16"></div>
        <error-dialog
          :error-dialog="ccError"
          :error-message="'Please ensure credit card information including first and last name is valid. Valid card types are: Visa, Mastercard and American Express'"
          :custom-error-method="true"
          :custom-error-close-button="$t('close')"
          @customErrorCloseEvent="ccError = false"
        ></error-dialog>
        <v-dialog
          v-model="purchaseError"
          max-width="700"
          persistent
        >
          <v-card min-height="33vh">
            <v-card-title class="justify-center font-weight-bold text-h5">
              {{ $t('common.apiErrors.title') }}
            </v-card-title>
            <v-card-text class="justify-center text-center body primmary--text">
              <v-row>
                <v-col>{{ ccValidationError || achValidationError }}</v-col>
              </v-row>
            </v-card-text>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-4 mt-4 font-weight-bold"
                    @click="clearPurchaseError(); purchaseError = false"
                  >
                    {{ $t('close') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="declinedDialog"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-title class="primary--text font-weight-bold">
              {{ $t('common.important.title') }}
            </v-card-title>
            <v-card-text>
              <p class="font-weight-bold text-center">
                <span
                  v-for="(line,key) in $t('common.important.lines')"
                  :key="key"
                  v-html="line.replaceAll('%%phone%%',`<a href=tel:${siteInfo.phone}>${siteInfo.phone}</a>`)
                    .replaceAll('%%email%%',`<a href=mailto:${siteInfo.email}>${siteInfo.email}</a> `)"
                ></span>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="font-weight-bold mr-0"
                @click="closeDeclinedDialog"
              >
                {{ $t('close') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <canadian-citizen
          :ineligible-dialog="ineligibleDialog"
          @closeInEligibleDialog="ineligibleDialog = false"
        />
        <v-dialog
          v-model="paymentMethod.error"
          max-width="700"
        >
          <v-card min-height="33vh">
            <v-card-title class="justify-center font-weight-bold text-h5">
              Oops!
            </v-card-title>
            <v-card-text class="justify-center text-center">
              <v-row>
                <v-col>
                  {{ paymentMethod.message }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-4 mt-4 font-weight-bold"
                    @click="paymentMethod.error = false"
                  >
                    {{ $t('close') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { mapActions, } from 'vuex'
  import 'v-credit-card/dist/VCreditCard.css'
  import CaTermThanks from './CaTermThanks'
  import { DateTime } from 'luxon'
  import numeral from 'numeral'
  import ChargeOverJS from '../../../plugins/chargeover'
  import CanadianCitizen from '../../../components/base/CanadianCitizen'
  import {
    PROVINCES,
    SUFFIXES,
    FACE_AMOUNTS,
  } from '../../../consts/ca-term'
  import payment from '@/views/dashboard/Condo/Payment.vue'
  import i18n from '@/i18n'
  import { formatCurrency } from '@/i18nValidate'
  import { localize } from 'vee-validate'
  import { localCurrency, siteInfo, getDefaultValue, DATE_FORMAT_YYYY, sortObject, deepCopy, deleteCompletedApps, keepLastSevenApps } from '@/utils/common'
  import firebase from 'firebase/app'
  import router from '@/router'
  import StepHeader from './Steps/StepHeader'
  import PreApplication from './Steps/PreApplication'
  import Assessment from './Steps/Assessment'
  import Questions from './Steps/Questions'
  import Terms from './Steps/Terms'
  import Beneficiaries from './Steps/Beneficiaries'
  import OfferSummary from './Steps/OfferSummary'
  import Payment from './Steps/Payment'
  import LoginSignup from './LoginSignup'
  import { applicationSteps } from '../../../utils/common'
  import idleMixin from '../../../mixins/IdleMixin'
  export default {
    name: 'QuicktermSurvey',
    components: {
      CanadianCitizen,
      StepHeader,
      PreApplication,
      Assessment,
      Questions,
      Terms,
      Beneficiaries,
      OfferSummary,
      Payment,
      CaTermThanks,
      LoginSignup
    },
    mixins: [idleMixin],
    // <----------------------------------------DATA---------------------------------------->
    data: () => ({
      steps: [
        { enter: null, totalTime: null },
        { enter: null, totalTime: null },
        { enter: null, totalTime: null },
        { enter: null, totalTime: null },
        { enter: null, totalTime: null },
        { enter: null, totalTime: null },
        { enter: null, totalTime: null },
      ],
      annualFees: 30,
      monthlyFees: 2.70,
      weightModel: null,
      applicationId: null,
      combinedData: {
        step1: null,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        step6: null
      },
      hasUserAndApplicationId: false,
      isPolicyPuchased: false,
      underwritingStepIndex: 1,
      isJourneyLoaded: false,
      isCalenderClicked: false,
      continueWithoutBeneficiary: false,
      language: '',
      bankDetails: {},
      cardDetails: {},
      marijuanaFormatted: null,
      alcoholFormatted: null,
      rawValue: null,
      grossHouseholdAnnualIncome: null,
      annualIncome: null,
      loansAndDebts: null,
      mortgageBalance: null,
      savings: null,
      optionsStep: 1,
      surveyQuestions: i18n.t('caTerm.surveyQuestions'),
      ineligible: false,
      ineligibleDialog: false,
      paymentMethod: {
        error: false,
        message: null
      },
      errorMessageDialog: false,
      partnerID: null,
      productMapping: {
        monthly: {
          10: 3,
          15: 4,
          20: 5
        },
        annual: {
          10: 7,
          15: 8,
          20: 9
        }
      },
      paymethod: 'creditcard',
      paymentOptions: i18n.t('paymentOptions'),
      show: false,
      declinedDialog: false,
      passportNumber: null,
      phcNumber: null,
      selectedProvince: null,
      dlNumber: null,
      identityConfirmed: false,
      identityTypes: i18n.t('acceptedDocuments'),
      selectedDocumentType: null,
      afterHoursDialog: false,
      applicationNotFoundDialog: false,
      maxDate: moment().subtract(18, 'years').format(DATE_FORMAT_YYYY),
      minDate: moment()
        .subtract(69, 'years')
        .format(DATE_FORMAT_YYYY),
      activeBirthDatePicker: null,
      time: false,
      purchaseError: false,
      ccError: false,
      expdate_month: null,
      expdate_year: null,
      cardexp: '',
      cardholder: '',
      cardnumber: '', // 4030000010001234
      creditCardType: '',
      cardsecuritycode: '', // 123
      billingFirstName: '',
      billingLastName: '',
      billingAddress: '',
      billingCity: '',
      billingProvince: '',
      billingPostalCode: '',
      billingPhone: '',
      billingEmail: '',
      billingCheckbox: true,
      paymentCheckbox: false,
      beneficiaries: [],
      healthConditions: sortObject(deepCopy(i18n.t('caTerm.medicalQuestions.questions'))),
      lifestyleUnderwritingQuestions: sortObject(deepCopy(i18n.t('caTerm.lifeStyleUnderWritingQuestions.questions'))),
      valid: true,
      selectedTerms: [],
      mentalHealthHistory: null,
      receivedTreatment: null,
      incarceration: null,
      underwritingStep: 1,
      familyHistory: null,
      colAttributes: { cols: '12', sm: '4', md: '4', class: 'pa-0 ma-0' },
      user: {
        firstName: null,
        lastName: null,
        middleInitial: null,
        suffix: null,
        address: {
          addr1: null,
          city: null,
          province: null,
          postalCode: null,
        },
        phoneNumber: null,
        emailAddress: null,
        password: null,
        policyLanguage: null,
        centimeters: null,
        inches: null,
        feet: null,
        kilograms: null,
        pounds: null,
        dob: null,
        sex: null,
        coverage: null,
        calculatedCoverage: null,
        term: 10,
        smoker: null,
      },
      income: '',
      policyLanguageDialog: false,
      units: 'imperial',
      citizen: '',
      replacePolicy: false,
      applicationStep: 1,
      status: 'lead',
      paymentFrequency: 'monthly',
      isQcOrOn: false
    }),
    computed: {
      currentScope () {
        return this
      },
      totalPercentage () {
        return this.beneficiaries.reduce((total, item) => {
          return total + parseInt(item.percentage)
        }, 0)
      },
      isAddressValidated () {
        return this.$store.getters.getIsAddressValidated
      },
      quoteCustomerDob () {
        return this.$store.getters.getQuoteCustomerDob
      },
      quoteCustomerSex () {
        return this.$store.getters.getQuoteCustomerSex
      },
      quoteCoverage () {
        return this.$store.getters.getQuoteCoverage
      },
      quoteTerm () {
        return this.$store.getters.quoteTerm
      },
      quoteCustomerIsCandian () {
        return this.$store.getters?.getQuoteSurveyQues?.[0]?.answer
      },
      quoteCustomerisSmoker () {
        return this.$store.getters?.getQuoteSurveyQues?.[1]?.answer
      },
      isPageLoaded () {
        return this.$store.getters?.getPageLoaded
      },
      siteInfo () {
        return siteInfo
      },
      payment () {
        return payment
      },
      monthlyPremiumLabel () {
        const formattedMonthlyTotal = this.formattedMonthlyTotal
        return `Monthly Premium: $${this.monthlyPremium}\nPolicy Fee: $2.70\nTotal Monthly: $${formattedMonthlyTotal}`
      },
      formattedMonthlyTotal () {
        const prem = this.$store.getters.getProperty('CATermPremium')?.monthlypremium || 0
        return localCurrency(this.$i18n.locale, parseFloat(+prem ?? 0) + this.monthlyFees)
      },

      annualPremiumLabel () {
        const formattedAnnualTotal = this.formattedAnnualTotal
        return `Annual Premium: $${this.annualPremium}\nPolicy Fee: $32.40\nTotal Annual: $${formattedAnnualTotal}`
      },

      formattedAnnualTotal () {
        const prem = this.$store.getters.getProperty('CATermPremium')?.annualpremium || 0
        return localCurrency(this.$i18n.locale, parseFloat(+prem) + this.annualFees)
      },
      bindPolicyPayMethod () {
        return this.paymethod === 'creditcard' ? 'cc' : 'ach'
      },
      customerName () {
        return this.paymethod === 'creditcard' ? this.cardDetails.name : this.bankDetails.name
      },
      createCustomerPayload () {
        return {
          company: this.customerName,
          name: this.customerName,
          email: this.user.emailAddress,
          phone: this.user.phoneNumber,
          address1: this.user.address.addr1,
          city: this.user.address.city,
          state: this.user.address.province,
          postalcode: this.user.address.postalCode
        }
      },
      storeCATermCreditCardPayload () {
        return {
          customer_id: this.$store.getters.getCATermCustomerId,
          number: this.cardDetails.number,
          expdate_month: this.cardDetails.expdate_month,
          expdate_year: this.cardDetails.expdate_year,
          cvv: this.cardDetails.cvv.toString(),
          name: this.cardDetails.name,
          address: this.billingAddress ? this.billingAddress : this.user.address.addr1,
          city: this.billingCity ? this.billingCity : this.user.address.city,
          state: this.billingProvince ? this.billingProvince : this.user.address.province,
          postcode: this.billingPostalCode ? this.billingPostalCode : this.user.address.postalCode,
          country: 'Canada'
        }
      },
      subscribeCATermPayload () {
        return {
          payment: {
            customerId: this.$store.getters.getProperty('CATermCustomerId'),
            cycle: this.paymentFrequency,
            paymethod: this.paymethod, // ach|creditcard
            paymethodId: this.$store.getters.getProperty('CATermPaymentId'),
          },
          premium: this.premiumPayload
        }
      },
      documentNumber () {
        const numberMapping = {
          Passport: this.passportNumber,
          'Driver\'s Licence': this.dlNumber,
          'Provincial Health Card': this.phcNumber
        }

        return numberMapping[this.selectedDocumentType] || ''
      },
      bindPolicyPayload () {
        const { billingFirstName: firstName, billingLastName: lastName, billingAddress: address, billingCity: city, billingProvince: province, billingPostalCode: postalCode, billingPhone: phone, billingEmail: email } = this
        const billingInfo = { firstName, lastName, address, city, province, postalCode, phone, email }
        const medical = Object.keys(this.healthConditions).map(key => {
          const { text, answer, conditionalQuestion, list } = this.healthConditions[key]
          const subQuestion = this.subQuestion(answer, conditionalQuestion)
          return {
            value: answer,
            text: text,
            list: list,
            subQuestion
          }
        })
        const lifestyle = Object.keys(this.lifestyleUnderwritingQuestions).map(key => {
          const { text, answer, conditionalQuestion, list } = this.lifestyleUnderwritingQuestions[key]
          const subQuestion = this.subQuestion(answer, conditionalQuestion)
          return {
            value: answer,
            text: text,
            list: list,
            subQuestion
          }
        })
        const lfs = [{
          text: i18n.t('caTerm.lifestyleQuestions.unitsOfMeasurement'),
          value: this.units
        }, {
          text: i18n.t('caTerm.lifestyleQuestions.height'),
          value: this.payloadHeight
        }, {
          text: i18n.t('caTerm.lifestyleQuestions.weight'),
          value: this.payloadWeight
        }]
        const payload = {
          customerId: this.$store.getters.getCATermCustomerId,
          userkey: this.userkey,
          replaceExisting: this.surveyQuestions.policyIntend.answer,
          partnerId: this.partnerID ?? null,
          policyUse: this?.bankDetails?.policyUse ?? 'p',
          basicInfo: {
            first_name: {
              key: 'first_name',
              value: this.user.firstName
            },
            m_i_: {
              key: 'm_i',
              value: this.user.middleInitial
            },
            last_name: {
              key: 'last_name',
              value: this.user.lastName
            },
            suffix: {
              key: 'suffix',
              value: this.user.suffix
            },
            sex_at_birth: {
              key: 'sex_at_birth',
              value: this.user.sex
            },
            date_of_birth: {
              key: 'date_of_birth',
              value: this.user.dob
            },
            address: {
              key: 'address',
              value: this.user.address.addr1
            },
            city: {
              key: 'city',
              value: this.user.address.city
            },
            province: {
              key: 'province',
              value: this.user.address.province
            },
            postal_code: {
              key: 'postal_code',
              value: this.user.address.postalCode
            },
            phone_number: {
              key: 'phone_number',
              value: this.user.phoneNumber
            },
            email: {
              key: 'email',
              value: this.user.emailAddress
            },
            language_of_correspondence: {
              key: 'language_of_correspondence',
              value: 'English'
            }
          },
          qa: {
            personalInformation: Object.keys(this.surveyQuestions).map(key => {
              const { text, answer, conditionalQuestion } = this.surveyQuestions[key]
              const subQuestion = this.subQuestion(answer, conditionalQuestion)
              return {
                value: answer,
                text,
                subQuestion
              }
            }),
            lifestyle: [...lfs, ...lifestyle],
            medical,
            declarations: i18n.t('caTerm.terms').map(i => {
              const term = i.replaceAll('%maxAmount%', formatCurrency(this.maxCoverageAmount, 'USD', i18n.locale, true))
              return { text: term, value: true }
            }),
          },
          financialInfo: {
            documentType: this.selectedDocumentType,
            documentNumber: this.documentNumber
          },
          coverage: {
            coverageAmount: this.user.coverage,
            product: this.user.term,
            smoker: this.surveyQuestions.tobaccoFor12Month.answer,
            paymentFrequency: this.paymentFrequency,
          },
          payment: {
            method: this.bindPolicyPayMethod,
            firstName: this.billingFirstName ? this.billingFirstName : this.user.firstName,
            lastName: this.billingLastName ? this.billingLastName : this.user.lastName,
            email: this.billingEmail ? this.billingEmail : this.user.emailAddress,
            desiredPaymentDate: '',
            billingInfo: {
              ...billingInfo
            }
          }
        }
        if (this.beneficiaries && this.beneficiaries.length > 0) {
          payload.beneficiaries = this.beneficiaries
        }
        return payload
      },
      personalQuestions () {
        return this.$store.getters.getProperty('CATermPersonalQuestions')
      },
      medicalQuestions () {
        return this.$store.getters.getProperty('CATermMedicalQuestions')
      },
      acknowledgements () {
        return this.$store.getters.getProperty('CATermAcknowledgements')
      },
      noInsuranceNeed () {
        if (typeof this.assessedInsuranceNeed !== 'number') {
          return false // or any default value you want to return when it's not a number
        }
        return this.assessedInsuranceNeed <= 0
      },

      greaterInsuranceNeed () {
        return this.assessedInsuranceNeed > this.user.calculatedCoverage
      },
      formattedCoverageAmount () {
        return localCurrency(this.$i18n.locale, this.user.calculatedCoverage)
      },
      noInsuranceNeedMessage () {
        return this.$t('caTerm.assessmentNeeds.noInsuranceNeed')
          .replace('%%contactLink%%', `<a                                        href="/contactus-ca"
                                      target="_blank">${this.$t('contactUs')}</a>`)
      },
      formattedValue: {
        get () {
          // Convert raw value to a formatted string with commas
          return this.rawValue ? `$${parseFloat(this.rawValue).toLocaleString('en-US', { maximumFractionDigits: 2 })}` : ''
        },
        set (value) {
          // Strip out dollar signs, commas, and other non-numeric characters
          // parseFloat would ignore non-numeric characters after digits, so we use a regular expression to remove them
          this.rawValue = parseFloat(value.replace(/[^0-9.]/g, ''))
        }
      },
      maxCoverageAmount () {
        return this.userAge >= 18 && this.userAge <= 50
          ? '$1,000,000'
          : '$500,000'
      },
      assessedInsuranceNeed () {
        return this.$store.getters.getProperty('CATermInsuranceNeed')
      },
      assessedInsuranceNeedFormatted () {
        return localCurrency(this.$i18n.locale, +this.assessedInsuranceNeed > 0 ? this.assessedInsuranceNeed : 0)
      },
      calculatorFields () {
        let fields = [
          {
            model: 'annualIncome',
            label: 'caTerm.assessmentNeeds.grossAnnualIncome',
            icon: 'mdi-currency-usd',
            rules: 'required|between:1,9999999'
          },
          {
            model: 'savings',
            label: 'caTerm.assessmentNeeds.savings',
            icon: 'mdi-percent',
            rules: 'required|between:1,9999999'

          },
          {
            model: 'mortgageBalance',
            label: 'caTerm.assessmentNeeds.mortgageBalance',
            icon: 'mdi-currency-usd',
            rules: 'required|between:1,9999999'

          },
          {
            model: 'loansAndDebts',
            label: 'caTerm.assessmentNeeds.loansDebts',
            icon: 'mdi-currency-usd',
            rules: 'required|between:1,9999999'

          },
        ]

        if (this.annualIncome < 10000) {
          fields.push({
            model: 'grossHouseholdAnnualIncome',
            label: 'caTerm.assessmentNeeds.grossHouseholdAnnualIncome',
            icon: 'mdi-currency-usd',
          // rules: [this.calculatorRules.requiredSevenDigits]
          })
        }

        return fields
      },
      needsAssessmentPayload () {
        return {
          userKey: this.userkey,
          grossIncome: getDefaultValue(this.annualIncome),
          grossHouseholdIncome: getDefaultValue(this.grossHouseholdAnnualIncome),
          savings: getDefaultValue(this.savings),
          mortgageBalance: getDefaultValue(this.mortgageBalance),
          loanDebts: getDefaultValue(this.loansAndDebts),
          dob: moment(this.user.dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
        }
      },
      userAge () {
        const formatDate = moment(this.user.dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
        return DateTime.fromISO(formatDate).diffNow('years').toObject().years * -1
      },
      allowedTermLengths () {
        const maxAgeWithTerm = 80
        const validTermLengths = [10, 15, 20]
        let terms = validTermLengths.filter(term => this.userAge + term <= maxAgeWithTerm)
        return terms
      },
      coverageAmounts () {
        if (this.userAge >= 18 && this.userAge <= 50) {
          return FACE_AMOUNTS
        } else if (this.userAge > 50) {
          return FACE_AMOUNTS.filter(amountObj => amountObj.value <= 500000)
        }
        return []
      },
      loginError () {
        return this.$store.getters.getloginerror
      },
      ccValidationError () {
        return this.$store.getters.getProperty('CATermCCError')
      },
      achValidationError () {
        return this.$store.getters.getProperty('CATermACHError')
      },
      productId () {
        return this.productMapping[this.paymentFrequency][this.user.term]
      },
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      errorMessage () {
        return this.$store.getters.getErrors
      },
      height () {
        if (this.user.centimeters) {
          return this.user.centimeters
        }
        if (typeof this.user.feet === 'number' && typeof this.user.inches === 'number') {
          return this.user.feet * 30.48 + this.user.inches * 2.54
        }
        return null
      },
      payloadHeight () {
        if (this.user?.centimeters) {
          return this.user.centimeters + ` ${this.$t('caTerm.lifestyleQuestions.centimeters')}`
        }
        if (typeof this.user?.feet === 'number' && typeof this.user.inches === 'number') {
          return this.user.feet + ` ${this.$t('caTerm.lifestyleQuestions.feet')} ` + this.user.inches + ` ${this.$t('caTerm.lifestyleQuestions.inches')}`
        }
        return null
      },
      payloadWeight () {
        if (this.user.kilograms) {
          return this.user.kilograms + ` ${this.$t('caTerm.lifestyleQuestions.kilograms')}`
        }
        if (this.user.pounds) {
          return this.user.pounds + ` ${this.$t('caTerm.lifestyleQuestions.pounds')}`
        }
        return null
      },
      weight () {
        if (this.user.kilograms) {
          return this.user.kilograms
        }
        if (this.user.pounds) {
          return this.user.pounds * 0.453592
        }
        return null
      },
      fullHomeAddress () {
        return `${this.user.address.addr1}, ${this.user.address.city}, ${this.user.address.province}, ${this.user.address.postalCode}`
      },
      fullName () {
        return `${this.user.firstName} ${this.user.middleInitial ? this.user.middleInitial.toUpperCase() + '. ' : ''}${this.user.lastName}`
      },
      userkey () {
        const user = this.$store.getters.activeUser ?? this.$store.getters.user
        if (user) {
          return user?.key ?? user?.id
        }
        return null
      },
      decision () {
        return this.$store.getters.getProperty('CATermDecision')
      },
      decisionPayload () {
        // const getHealthConditions = this.healthConditions.map(({ active, ...item }) => item)
        const apneaAnswer = this?.healthConditions.sleepApnea?.conditionalQuestion?.answer?.toLowerCase()
        const formattedApnea = apneaAnswer === this.$t('common.yes').toLowerCase()
        this.joints(this.lifestyleUnderwritingQuestions.marijuanaUse?.conditionalQuestion?.answer)
        const {
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          dob,
          sex,
          smoker
        } = this.user
        return {
          userInfo: {
            firstName,
            lastName,
            phoneNumber,
            emailAddress,
            dob,
            sex,
            smoker
          },
          partnerId: this.partnerID,
          questionAndAnswers: [
            ...Object.values(this.surveyQuestions),
            ...Object.values(this.lifestyleUnderwritingQuestions),
            ...Object.values(this.healthConditions)
          ],
          existingReplace: this.surveyQuestions.policyIntend.answer,
          currentlyAbsent: this.surveyQuestions.absentFor14DCurrent.answer,
          previouslyAbsent: {
            relevant: Boolean(this.surveyQuestions.absentFor14DPast.conditionalQuestion.answer),
            timeframe: this.surveyQuestions.absentFor14DPast.conditionalQuestion.answer || '11Y+'
          },
          canadianResident: this.surveyQuestions.isCanadian.answer,
          tobacco: this.surveyQuestions.tobaccoFor12Month.answer,
          riskyOccupation: this.lifestyleUnderwritingQuestions.riskyOccupation.answer,
          criminalOffences: this.lifestyleUnderwritingQuestions.criminalOffences.answer,
          alcoholUse: this.lifestyleUnderwritingQuestions.alcoholUse?.answer || 0,
          alcoholUsePast10Y: this.lifestyleUnderwritingQuestions.alcoholUsePast10Y.answer,
          height: this.height,
          weight: this.weight,
          outsideCa: this.lifestyleUnderwritingQuestions.outsideCa.answer,
          declinedOrRescinded: this.lifestyleUnderwritingQuestions.declinedOrRescinded.answer,
          recreationalDrugsPast5Y: this.lifestyleUnderwritingQuestions.recreationalDrugsPast5Y.answer,
          marijuanaUse: {
            relevant: Boolean(this.lifestyleUnderwritingQuestions.marijuanaUse.answer),
            frequency: this.marijuanaFormatted || '0-3'
          },
          extremeSports: this.lifestyleUnderwritingQuestions.extremeSports.answer,
          medicalHistory: {
            cancer: this.healthConditions.cancer.answer,
            fibrosis: this?.healthConditions.fibrosis.answer,
            sleepApnea: this?.healthConditions.sleepApnea?.answer,
            sleepApneaCpap: formattedApnea,
            circulatory: {
              heart: this.healthConditions.heart.answer,
              bloodPressure: false
            },
            immunity: this.healthConditions.immunity.answer,
            neurologic: {
              brainDisorder: this.healthConditions.brainDisorder.answer,
              cognitiveImpairment: this.healthConditions.cognitiveImpairment.answer
            },
            musculoskeletal: this.healthConditions.musculoskeletal.answer,
            psychological: {
              schizophrenia: this.healthConditions.schizophrenia.answer,
              depression: this.healthConditions.depression.answer,
              anxiety: this?.healthConditions.anxiety.answer
            },
            generalHealth: this.healthConditions.generalHealth.answer,
            otherIssues: {
              fourWeekTreatment: this.healthConditions.fourWeekTreatment?.answer || null,
              mamograms: this.healthConditions.mamograms.answer,
              followups: this.healthConditions.followups.answer,
              threeMonthSymptoms: this.healthConditions.threeMonthSymptoms.answer,
            }
          },
          familyMedicalHistory: {
            twoDiagnosed: this.healthConditions.twoDiagnosed.answer,
            oneDiagnosed: this.healthConditions.oneDiagnosed.answer
          }
        }
      },
      // Consts
      PROVINCES: () => PROVINCES,
      SUFFIXES: () => SUFFIXES,
      FACE_AMOUNTS: () => FACE_AMOUNTS,
      OFFER_LIST: () => i18n.t('caTerm.approval.summaryPoints'),
      // Vuex Getters
      CATermLifeTerm () {
        return this.$store.getters.getProperty('CATermLifeTerm')
      },
      CATermLifeCoverage () {
        return numeral(this.$store.getters.getProperty('CATermLifeCoverage')).format('$0,0')
      },
      error () {
        return this.$store.getters.getErrors
      },
      loading () {
        return this.$store.getters.getloading ?? true
      },
      weightLabel () {
        return this.units === 'metric' ? this.$i18n.t('caTerm.lifestyleQuestions.kilograms') : this.$i18n.t('caTerm.lifestyleQuestions.pounds')
      },
      calcage () {
        if (this.user.dob) {
          const birthDate = moment(
            this.user.dob,
            'YYYY-MMM-DD'
          )

          if (!birthDate.isValid()) {
            throw new Error('Invalid date format')
          }

          const age = moment().diff(this.user.dob, 'years')
          return age
        }

        return null
      },

      // Premium Computations
      monthlyPremium () {
        const prem = this.getIntValue(this.$store.getters.getProperty('CATermPremium')?.monthlypremium || 0)
        return localCurrency(this.$i18n.locale, prem)
      },
      annualPremium () {
        const prem = this.getIntValue(this.$store.getters.getProperty('CATermPremium')?.annualpremium || 0)
        return localCurrency(this.$i18n.locale, prem)
      },
      premiumPayload () {
        return {
          gender: this.user.sex,
          smoker: this.surveyQuestions.tobaccoFor12Month?.answer,
          age: this.calcage,
          product: this.user.term,
          amount: this.user.coverage ?? 1,
        }
      },
      formattedTerms () {
        let terms = this.termLengths.map((item) => item + ' Years')
        return terms
      },
      termLengths () {
        return [10, 15, 20]
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      // Policy Details
      policy () {
        let policy = ''
        return policy
      },
      userData () {
        return this.$store.getters.user
      },
    },
    watch: {

      currentScope: {
        deep: true,
        handler (val, oldVal) {

        }
      },
      hasUserAndApplicationId (value, oldValue) {
        if (value) {
          this.getApplication(this.applicationId)
        }
      },
      async applicationId (value) {
        if (value && this.userkey) {
          this.hasUserAndApplicationId = true
        }
      },
      userkey: {
        handler (newValue, oldValue) {
          if (newValue && this.applicationId) {
            this.hasUserAndApplicationId = true
          }
        },
        deep: true
      },
      // Watching applicationStep
      async applicationStep (val, oldStep) {
        if (oldStep) {
          this.recordExitTime(oldStep)
        }
        if (val <= 7) {
          this.recordEnterTime(val)
        }
        // this.applicationId = this.$route.params?.applicationId ?? null
        const scrollEle = document.querySelectorAll('.scrollbar-item')?.[val - 1]
        if (scrollEle) {
          scrollEle.scrollIntoView()
        }
        this.handleStepChange(val)
        const statusNotInlcuded = ['opportunity', 'completed']
        if (val >= 5 && !statusNotInlcuded.includes(this.status)) {
          this.status = 'opportunity'
        }
        await this.updateUserJourney()
        if (val && this.applicationId && applicationSteps[val] !== this.$route.params.step) {
          await this.$router.push(`/canadianterm/survey/${applicationSteps[val]}/${this.applicationId}`)
        }
      },

      // Watching underwritingStep
      async underwritingStep (val, oldVal) {
        // this.applicationId = this.$route.params?.applicationId ?? null
        this.handleStepChange(val)
        if (val !== oldVal) {
          await this.updateUserJourney()
        }
      },

      // Watching optionsStep
      async optionsStep (val, oldVal) {
        this.handleStepChange(val)
        if (val !== oldVal) {
          await this.updateUserJourney()
        }
      },
      selectedDocumentType (val) {
        this.passportNumber = null
        this.dlNumber = null
        this.phcNumber = null
      },
      selectedProvince (val) {
        this.passportNumber = null
        this.dlNumber = null
        this.phcNumber = null
      },
      'lifestyleUnderwritingQuestions.declinedOrRescinded.answer' (val) {
        if (val === false) {
          this.lifestyleUnderwritingQuestions.declinedOrRescinded.conditionalQuestion.answer = null
        }
      },
      'lifestyleUnderwritingQuestions.marijuanaUse.answer' (val) {
        if (val === false) {
          this.lifestyleUnderwritingQuestions.marijuanaUse.conditionalQuestion.answer = null
        }
      },
      'surveyQuestions.isCanadian.answer' (answer) {
        this.ineligibleDialog = this.ineligible = (answer === false)
      },
      'surveyQuestions.absentFor14DPast.answer' (answer) {
        if (answer === false) {
          this.surveyQuestions.absentFor14DPast.conditionalQuestion.answer = null
        }
      },
      assessedInsuranceNeed (newVal, oldValue) {
        const closestValue = this.getClosestInsuranceValue(!newVal && this.user.coverage ? this.user.coverage : newVal)
        if (this.quoteCoverage) {
          this.user.coverage = this.quoteCoverage
        }
        if (!this.quoteCoverage && !this.user.coverage) {
          this.user.coverage = closestValue
        }
        this.user.calculatedCoverage = closestValue
      },
      errorMessage (val) {
        this.errorMessageDialog = !!val
      },
      decision (val) {
        if (val === 'Declined') {
          this.declinedDialog = true
        }
      }
    },
    created () {
      if (this.$route.params?.applicationId) { this.$store.commit('setLoading', true) }
      if (!this.$route.params?.step) {
        this.$router.push('/canadianterm/survey/pre-application')
      }
      this.$store.commit('setApplicationID', this.$route.params.applicationId)
      this.stepTimeAction = this.customStepTimeAction
      this.performIdleAction = this.customPerformIdleAction
    },
    async mounted () {
      this.$store.commit('setStateProperty', { property: 'CATermCustomerSubscriptionId', value: null })
      this.$store.commit('setStateProperty', { property: 'CATermCustomerId', value: null })
      this.$store.commit('setStateProperty', { property: 'CATermPaymentId', value: null })

      this.applicationId = this.$route.params.applicationId
      this.partnerID = this.$store.getters.getPartnerId ?? null
      this.checkAfterHours()
      if (!this.applicationId && !this.quoteCustomerDob) {
        router.push('/quote')
      }
      if (!this.applicationId && this.quoteCustomerDob) {
        this.isJourneyLoaded = true
      }
      this.$store.dispatch('setCATermInsuranceNeed', null)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      ChargeOverJS.load(process.env.VUE_APP_CHARGEOVERJS_INSTANCE, process.env.VUE_APP_CHARGEOVERJS_TOKEN)
      localize(this.$i18n.locale)
      this.$nextTick(() => {
        this.language = this.$Storage.getCookie('locale') || 'en'
        if (!this.applicationId && this.quoteCustomerDob) {
          this.user.dob = this.quoteCustomerDob
          this.user.sex = this.quoteCustomerSex
          this.surveyQuestions.isCanadian.answer = this.quoteCustomerIsCandian
          this.surveyQuestions.tobaccoFor12Month.answer = this.quoteCustomerisSmoker
        }
        const scrollEle = document.querySelectorAll('.scrollbar-item')?.[this.applicationStep - 1]
        if (scrollEle) {
          scrollEle.scrollIntoView()
        }
        this.$store.commit('setError', '')
      })
      try {
        const data = await this.$store.dispatch('getSettings')
        const { annualFees = 30, monthlyFees = 2.70 } = data
        this.annualFees = annualFees
        this.monthlyFees = monthlyFees
      } catch (e) {
        console.log({ e }, 'Returning from here')
        if (!this.userkey) {
          if (this.$route.params?.applicationId) {
            this.$router.push('/')
            this.$store.commit('setLoading', false)
          }
        }
      }
    },
    async beforeDestroy () {
      this.$store.dispatch('setCATermInsuranceNeed', null)
    },

    // <---------------------------------METHODS--------------------------------->
    methods: {
      checkAndUpdateApplicationStep () {
        if (!this.error) {
          this.applicationStep++
        }
      },
      checkAndUpdateOptionsStep () {
        if (!this.error) {
          this.optionsStep++
        }
      },
      customStepTimeAction () {
        this.recordEnterTime(this.applicationStep)
        localStorage.removeItem('redirectUrl')
      },
      async customPerformIdleAction () {
        this.recordExitTime(this.applicationStep)
        await this.updateUserJourney()
      },
      recordEnterTime (step) {
        if (step <= 7) {
          const currentTime = Math.floor(new Date().getTime() / 1000)
          const stepData = this.steps[step - 1]
          // If there's an existing enter time, update the total time spent
          if (stepData?.enter !== null) {
            stepData.totalTime += (currentTime - stepData.enter) // Convert milliseconds to seconds
          }
          // Update the enter time to the current time
          stepData.enter = currentTime
        }
      },
      recordExitTime (step) {
        if (step <= 7) {
          const currentTime = Math.floor(new Date().getTime() / 1000)
          const stepData = this.steps[step - 1]
          // Only update totalTime if there's a valid enter time
          if (stepData?.enter !== null) {
            stepData.totalTime += (currentTime - stepData.enter) // Convert milliseconds to seconds
            stepData.enter = null // Clear the enter time
          }
        }
      },
      ...mapActions(['searchAddress', 'selectAddress', 'validateAddress']),
      handleWeightSet (val) {
        const setWeight = val !== 0 ? val : null
        this.weightModel = setWeight
      },
      joints (newValue = 0) {
        let formattedValue

        if (newValue <= 3) formattedValue = '0-3'
        else if (newValue <= 6) formattedValue = '4-6'
        else if (newValue > 6) formattedValue = '7+'

        this.marijuanaFormatted = formattedValue
      },
      getIntValue (value) {
        if (value % 1 !== 0) {
          return value
        } else {
          return Math.floor(value)
        }
      },
      updateCombinedData (step, component) {
        this.combinedData[step] = component
      },
      router () {
        return router
      },
      async getApplication (val) {
        if (val) {
          try {
            this.$store.commit('setLoading', true)
            const ref = firebase.database().ref(`users/${this.userkey}/applications/${val}`)
            const snapshot = await ref.once('value')
            const application = snapshot.val() ?? []
            if (application?.length === 0) {
              this.$router.push('/canadianterm')
            }
            // deleteCompletedApps(snapshot, ref)
            const {
              user,
              weight = null,
              kilograms = 0,
              pounds = 0,
              optionsStep = 1,
              units = 'imperial',
              weightModel = null,
              healthConditions,
              partnerId,
              preApplication,
              lifestyle,
              applicationStep = 1,
              underwritingStep = 1,
              selectedTerms = [],
              continueWithoutBeneficiary = false,
              status = 'lead',
              beneficiaries = [], calculatorFields: {
                annualIncome = null,
                grossHouseholdAnnualIncome = null,
                savings = null,
                mortgageBalance = null,
                loansAndDebts = null
              } = {},
              stepTimes
            } = application
            if (!annualIncome) {
              this.$store.commit('setLoading', false)
              this.isJourneyLoaded = true
              return
            }
            // It should default values for till step 7
            for (let i = 0; i < 7; i++) {
              this.steps[i].totalTime = stepTimes[i] || 0
              this.steps[i].enter = null
            }
            this.user = user
            this.surveyQuestions = sortObject(preApplication)
            this.partnerID = partnerId
            this.applicationStep = applicationStep
            this.status = status
            this.underwritingStep = underwritingStep
            this.units = units
            this.lifestyleUnderwritingQuestions = sortObject(lifestyle)
            this.weightModel = weight?.value
            this.healthConditions = sortObject(healthConditions)
            this.selectedTerms = selectedTerms
            this.continueWithoutBeneficiary = continueWithoutBeneficiary ?? false
            this.beneficiaries = beneficiaries
            this.annualIncome = annualIncome
            this.grossHouseholdAnnualIncome = grossHouseholdAnnualIncome ?? 0
            this.savings = savings
            this.mortgageBalance = mortgageBalance
            this.loansAndDebts = loansAndDebts
            this.weight = weight ?? 0
            this.user.kilograms = kilograms ?? 0
            this.user.pounds = pounds
            this.weightModel = weightModel
            this.optionsStep = optionsStep
            // this.optionsStep = this?.annualIncome ? 2 : 1
            this.underwritingStepIndex = this.underwritingStep >= 3 ? 5 : 1
            if (applicationStep > 2 || (annualIncome || grossHouseholdAnnualIncome)) {
              await this.$store.dispatch('CATermNeedsAssessment', this.needsAssessmentPayload)
              await this.fetchRates()
            }
            this.isJourneyLoaded = true
          } catch (e) {
            console.log({ e })
          } finally {
            this.$store.commit('setLoading', false)
          }
        }
      },
      async updateUserJourney () {
        if (!this.applicationId) {
          this.$store.commit('setLoading', true)
        }
        try {
          this.applicationId = this.$route.params?.applicationId ?? null
          if (this.status === 'completed') {
            return
          }
          const stepTimes = this.steps.map(step => step.totalTime)
          const ref = firebase.database().ref(`users/${this.userkey}/applications`)
          const snapshot = (await ref.once('value')).val()
          const numberOfApplications = snapshot ? Object.keys(snapshot)?.length : 0
          const lfs = [{
            text: i18n.t('caTerm.lifestyleQuestions.unitsOfMeasurement'),
            value: this.units
          }, {
            text: i18n.t('caTerm.lifestyleQuestions.height'),
            value: this.payloadHeight
          }, {
            text: i18n.t('caTerm.lifestyleQuestions.weight'),
            value: this.weightModel
          }]
          const dataToBeSaved = {
            user: { ...this.user },
            partnerId: this?.partnerID ?? null,
            optionsStep: this.optionsStep,
            healthConditions: { ...this.healthConditions },
            preApplication: { ...this.surveyQuestions },
            units: this.units,
            lifestyle: { ...this.lifestyleUnderwritingQuestions },
            applicationStep: this.applicationStep >= 6 ? 6 : this.applicationStep,
            underwritingStep: this.underwritingStep,
            selectedTerms: this.selectedTerms,
            beneficiaries: this.beneficiaries,
            continueWithoutBeneficiary: this.totalPercentage === 100 || this.continueWithoutBeneficiary,
            status: this.applicationStep > 7 ? 'completed' : this.status,
            weight: this.weight,
            weightModel: this.weightModel,
            kilo: this?.user?.kilograms ?? null,
            pounds: this.user.pounds ?? null,
            calculatorFields: {
              annualIncome: this.annualIncome,
              grossHouseholdAnnualIncome: this.grossHouseholdAnnualIncome,
              savings: this.savings,
              mortgageBalance: this.mortgageBalance,
              loansAndDebts: this.loansAndDebts
            },
            createdAt: moment().format('YYYY-MM-DD'),
            stepTimes: stepTimes
          }
          if (this.applicationId) {
            await ref.child(this.applicationId).set({ ...snapshot[this.applicationId], ...dataToBeSaved })
            this.isJourneyLoaded = true
            this.$store.commit('setLoading', false)
          } else {
            if (numberOfApplications > 6) {
              await keepLastSevenApps(snapshot, ref)
            }
            await ref.push({ ...dataToBeSaved }).then(async (record) => {
              this.$store.commit('setApplicationID', record.getKey())
              await this.$router.push(`/canadianterm/survey/${applicationSteps[this.applicationStep]}/${record.getKey()}`)
              this.$store.commit('setLoading', false)
            })
          }
        } catch (e) {
          // console.log('Error: ', e)
          this.$store.commit('setLoading', false)
        }
      },
      localCurrency,
      formatCurrency,
      subQuestion (answer, conditionalQuestion) {
        return conditionalQuestion && conditionalQuestion.condition === answer ? {
          value: conditionalQuestion?.answer,
          text: conditionalQuestion?.text,
          list: conditionalQuestion?.list,
        } : null
      },
      handleStepChange (val) {
        this.scrollToTop()
      },
      getClosestInsuranceValue (need) {
        let insuranceOptions = [
          { text: '$100K', value: 100000 },
          { text: '$250K', value: 250000 },
          { text: '$400K', value: 400000 },
          { text: '$500K', value: 500000 },
          { text: '$600K', value: 600000 },
          { text: '$750K', value: 750000 },
          { text: '$1M', value: 1000000 },
        ]

        // If the need is negative or 0, return the $500K option
        if (need <= 0) {
          return 500000
        }

        // If age is over 50, filter out options greater than $500K
        if (this.calcage > 50) {
          insuranceOptions = insuranceOptions.filter(option => option.value <= 500000)
        }

        // Filter options that are less than the need
        const eligibleOptions = insuranceOptions.filter(option => option.value >= need)

        // If there are eligible options, return the smallest of them
        if (eligibleOptions.length > 0) {
          eligibleOptions.sort((a, b) => a.value - b.value)
          return eligibleOptions[0].value
        }

        // If no eligible options, return the largest available option
        // (this is a fallback case which should rarely happen)
        insuranceOptions.sort((a, b) => b.value - a.value)
        return insuranceOptions[0].value
      },
      clearErrorMessage () {
        this.$store.dispatch('clearError')
      },
      async calculateInsuranceNeed () {
        // await this.updateUserJourney()
        // Validate the form before proceeding
        this.isCalenderClicked = false
        const isFormValid = await this.$refs.assessmentForm.validate()
        if (isFormValid) {
          // Form is valid, dispatch the action
          await this.$store.dispatch('CATermNeedsAssessment', this.needsAssessmentPayload)
        } else {
        // Form is not valid, handle validation errors or display a message
        // You can optionally display validation error messages here.
        }
      },
      clearPurchaseError () {
        this.$store.dispatch('resetCATermPurchaseErrors')
      },
      async closeDeclinedDialog () {
        this.status = 'knockedout'
        this.declinedDialog = false
        this.recordExitTime(this.applicationStep)
        await this.updateUserJourney()
        this.$store.dispatch('setCATermDecision', null)
        this.$router.push('/')
      },
      checkAfterHours () {
        const now = DateTime.now().setZone('America/New_York') // Set the time zone to Eastern Time (ET)
        const currentHour = now.hour
        const currentMinute = now.minute
        const dayOfWeek = now.weekday

        // Convert current time to minutes for easier comparison
        const currentTimeInMinutes = currentHour * 60 + currentMinute

        // Define the start and end times in minutes
        const startTime = 20 * 60 + 30 // 8:30 PM in minutes
        const endTime = 8 * 60 + 59 // 8:59 AM in minutes

        // Check if today is a weekday (Monday to Friday)
        const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5

        // Check if current time is between 8:30 PM and 8:59 AM and if it's a weekday
        if ((currentTimeInMinutes >= startTime || currentTimeInMinutes <= endTime) && isWeekday) {
          this.afterHoursDialog = true
        } else {
          this.afterHoursDialog = false
        }
      },
      advanceToNextStep () {
        this.applicationStep = 2
        this.optionsStep = 2
      },
      async fetchRates () {
        await this.$store.dispatch('getCATermPremium', this.premiumPayload)
      },
      scrollToError () {
        const element = document.querySelector('.error--text')
        if (element) {
          element.scrollIntoView()
        }
      },
      scrollToTop () {
        const scrollEle = document.querySelector('body')
        if (scrollEle) {
          scrollEle.scrollIntoView()
        }
      },
      checkWeight () {
        if (this.units === 'imperial' && this.pounds) {
          let poundsToKg = Math.round(this.pounds * 0.453592)
          this.policyData.weight = poundsToKg
        // this.weightModel = poundsToKg
        } else if (this.units === 'metric' && this.kilograms) {
          this.policyData.weight = this.kilograms
        // this.weightModel = this.kilograms
        }
      },
      async nextquestion (completedQuestion) {
        this.$gtag.event('CA Term Survey v1', {
          userId:
            this.$store.getters.getuser && this.$store.getters.getuser.id
              ? this.$store.getters.getuser.id
              : 'Visitor',
          data_point: `Step completed: ${completedQuestion}`,
          action: 'Step completed',
          data: `${completedQuestion}`,
        })
        this.$gtag.event('CA Term Survey v1', {
          question: completedQuestion,
          campaign: 'none',
        })
        this.checkAndUpdateApplicationStep()
      },
      back () {
        this.applicationStep--
        if (this.applicationStep < 1) {
          this.applicationStep = 1
        }
      }
    },
  }
</script>
<style lang="scss">
@import "@/sass/caterm/caterm.scss";
</style>
<style scoped>
#dashboard{
  display: none;
}
</style>
