<template>
  <v-row
    ref="addressList"
    class="padding-custom"
  >
    <v-col
      cols="11"
      sm="12"
      md="12"
      lg="12"
    >
      <validation-provider
        v-slot="{ errors }"
        name="Address"
        rules="required"
      >
        <v-text-field
          v-model="currentAddressLocal"
          :label="$t('caTerm.basicInfo.address')"
          outlined
          :error-messages="errors"
          @input="getAddress"
          ref="addressField"
        ></v-text-field>
        <ul
          v-if="caTermAddresses?.length && showAddress"
          v-scroll-y
          class="address-list"
        >
          <li
            v-for="(address, index) in caTermAddresses"
            :key="index"
            class="address-item"
            @click="handleSelectAddress(address)"
          >
            {{ address.Text }} {{ address.Description }}
          </li>
        </ul>
      </validation-provider>
    </v-col>
    <v-dialog
      v-model="validateAddressError"
      max-width="700"
      persistent
    >
      <v-card min-height="10vh">
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>{{ validateAddressError }}</v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="clearMessage"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapActions, mapMutations } from 'vuex'
  export default {
    props: {
      address: {
        type: String,
        required: false
      }
    },
    data: () => ({
      showAddress: true,
      inputTimeout: null,
      currentAddressLocal: ''
    }),
    methods: {
      ...mapActions(['searchAddress', 'selectAddress', 'validateAddress']),
      ...mapMutations(['setAddressKeyword', 'setValidationAddressError', 'setCurrentAddress']),
      getAddress (val) {
        if (val?.length >= 3 && !this.isAddressApiDown) {
          clearTimeout(this.inputTimeout)
          this.inputTimeout = setTimeout(() => {
            this.showAddress = true
            this.setAddressKeyword(val)
            this.searchAddress()
            this.setCurrentAddress('')
            this.currentAddressLocal = val
          }, 1000)
        }
      },
      closeAddressList () {
        if (!this?.$refs?.addressList?.contains(event.target)) {
          this.showAddress = false
        }
      },
      clearMessage () {
        this.setValidationAddressError('')
      },
      async handleSelectAddress (address) {
        await this.selectAddress(address)
        if (this.currentAddress) {
          this.currentAddressLocal = this?.currentAddress?.Text
          const [city, province, zipcode] = this?.currentAddress?.Description?.split(',').map(field => field.trim())
          const completeAddress = {
            Text: this.currentAddress.Text,
            City: city,
            Province: province,
            PostalCode: zipcode
          }
          this.$emit('update:completeAddress', completeAddress)
          this.showAddress = false
        }
        // await this.validateAddress()
      }
    },
    computed: {
      caTermAddresses () {
        return this.$store.getters.getCaTermAddreses
      },
      currentAddress () {
        return this.$store.getters.getCurrentAddress
      },
      isAddressApiDown () {
        return this.$store.getters.getAddressApiDown
      },
      validateAddressError () {
        return this.$store.getters.getValidateAddressError
      },
    },
    mounted () {
      document.addEventListener('click', this.closeAddressList)
      this.currentAddressLocal = this?.address
    }
  }
</script>
<style scoped>
.address-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  list-style: none;
  position: relative;
  line-height: 34px;
  top: -31px;
  max-height: 250px;
  overflow-y: auto;
  max-width: 370px;
}

.address-item {
  cursor: pointer;
  padding: 8px 0;
}

.address-item:hover {
  background-color: #f0f0f0;
}

.display-inline {
  width: 50%;
  display: inline-block;
}
.padding-custom{
   padding: 13px;
}
</style>
